import { ErrorMessage, Form, Formik} from "formik";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { Button,  Divider,  Label } from "semantic-ui-react";
import { useStore } from "../app/store/store";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../app/lib/MyTextInputNonFluid";
import MyTextInputNonFluidPassword from "../app/lib/MyTextInputNonFluidPassword";
import { toast } from "react-toastify";


export default observer(function LoginForm(){
    const {userStore} = useStore();
    const [showPassword, setShowPassword] = useState(false);
    const formRef = useRef<any>();

    const togglePassword = ()=> {
        setShowPassword(!showPassword);
    };


    const resetPassword = () => {
        if(formRef.current){
            const emailAddr = formRef.current.values["email"];
            if (!emailAddr){
                toast.error("Email is required !");
                return;
            }
            else{
                userStore.resetPassword(emailAddr);
                toast.success("Email sent to " + emailAddr);
            }
        }
    };
    const validationSchema = Yup.object({
        email: Yup.string().required("Email is required"),
        password: Yup.string().required("Password is required")
    });

    return (
        <Formik innerRef={formRef} initialValues={{email: "", password:"", deviceID: "", error: null}} 
                onSubmit={
                    (values,{setErrors}) => userStore.login(values).catch(err => setErrors({error: 'Unable to Login to the System'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid  name="email" placeholder="Email"></MyTextInputNonFluid>

                        <MyTextInputNonFluidPassword togglePassword={togglePassword} name="password" placeholder="Password" type={!showPassword ? "password" : ""} showPassword={showPassword}></MyTextInputNonFluidPassword>
                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="sign-in" loading={isSubmitting} positive content="Sign In" type="submit" fluid></Button>
                        <Divider hidden/>
                        <Button  type="button" content="Reset Password"  fluid onClick={()=>resetPassword()}></Button>

                    </Form>
                
            )}
        </Formik>
    )
})